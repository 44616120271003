// Npm
import { StyleSheet, css } from "aphrodite";
import { useRef, useState, useEffect, createRef } from "react";
import {
  Stage,
  Layer,
  Group,
  Image as KonvaImage,
  Rect,
  Text as KonvaText,
} from "react-konva";
import Konva from "konva";
import numeral from "numeral";

// Component
import mural from "../../assets/mural.webp";
import Wrapper from "../Wrapper";
import Avatar from "../Avatar";
import { Colors } from "../../utils/colors";

import { abbrevEthAddress } from "../../utils/helpers";
import { useInterval } from "../../config/hooks";

/**
 * IMPORTANT VALUES:
 * Canvas doesn't autoresize.
 * */
const ASPECT_RATIO = 1080 / 1920;
const AVATAR_ASPECT_RATIO = 458 / 653;
const NUM_IN_ROW = 6;
const FETCH_AVATAR_INTERVAL = 20000;

const MuralCanvas = ({ windowWidth }) => {
  const canvasRef = useRef();
  const originalZIndexAvatars = useRef();
  const [backgroundImage, setBackgroundImage] = useState(loadBackgroundImage());

  const [muralWidth, setMuralWidth] = useState(window.innerWidth);
  const [muralHeight, setMuralHeight] = useState(window.innerHeight);

  // Avatars from API
  const [userAvatars, setUserAvatars] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userAddress, setUserAddress] = useState(
    window.localStorage.getItem("eth_address")
  );
  const [fetchNumber, setFetchNumber] = useState(1);
  const [count, setCount] = useState(1);

  // Formatted Avatars for Canvas
  const [formattedAvatars, setFormattedAvatars] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [tooltipImage, setTooltipImage] = useState(null);

  const containerRef = useRef(null);
  const tooltipRef = useRef(null);
  const nftRef = useRef(null);

  useEffect(() => {
    fadeInNewAvatars(formattedAvatars);
  }, [formattedAvatars]);

  // useInterval(
  //   () => {
  //     fetchAllAvatars(fetchNumber);
  //   },
  //   count < 100 ? null : FETCH_AVATAR_INTERVAL
  // );

  useEffect(() => {
    fetchAllAvatars(fetchNumber);
  }, []);

  useEffect(() => {
    if (canvasRef?.current && windowWidth) {
      calculateCanvasDimension(null);
    }
  }, [windowWidth]);

  async function fetchMyAvatars() {
    const url =
      process.env.REACT_APP_ENV === "production"
        ? "https://api.lobby3.io"
        : process.env.REACT_APP_ENV === "staging"
        ? "https://staging-api.lobby3.io"
        : "http://localhost:8000";

    const user = userAddress;

    return fetch(
      url + `/profile/?eth_address=${user}&nft_set=true&page_size=10`
    )
      .then((data) => data.json())
      .then((body) => {
        const { results } = body;

        return results;
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function fetchAllAvatars(numFetch) {
    const myAvatarFetch = numFetch === 1 ? fetchMyAvatars() : null;
    const otherAvatarFetch = fetchMuralAvatars(numFetch);
    const fetches = await Promise.all([myAvatarFetch, otherAvatarFetch]);
    const myAvatars = fetches[0] ? fetches[0] : userAvatars;
    const otherAvatars = fetches[1];
    const otherAvatarsWithoutMine = [];

    const myAvatarHash = {};

    for (let i = 0; i < myAvatars.length; i++) {
      const curAvatar = myAvatars[i];
      myAvatarHash[curAvatar.id] = true;
    }

    for (let i = 0; i < otherAvatars.length; i++) {
      const curAvatar = otherAvatars[i];
      if (!myAvatarHash[curAvatar.id]) {
        otherAvatarsWithoutMine.push(curAvatar);
      }
    }

    const results = [...myAvatars, ...otherAvatarsWithoutMine];

    calculateCanvasDimension(results, [...formattedAvatars]);
    setAvatars(results);
    setUserAvatars(myAvatars);
  }

  async function fetchMuralAvatars(numFetch) {
    if (numFetch === 0) {
      setIsLoading(true);
    }

    const url =
      process.env.REACT_APP_ENV === "production"
        ? "https://api.lobby3.io"
        : process.env.REACT_APP_ENV === "staging"
        ? "https://staging-api.lobby3.io"
        : "http://localhost:8000";

    return fetch(
      url +
        `/profile/?page=${numFetch}&page_size=${
          125 - userAvatars.length
        }&nft_set=true`
      // url + `/profile/mural/?${user ? `user=${user}&` : ""}page=${numFetch}`
    )
      .then((data) => data.json())
      .then((body) => {
        const { results, count, next } = body;

        if (!next && count > 100) {
          setFetchNumber(1);
        } else {
          setFetchNumber(fetchNumber + 1);
        }

        setCount(count);
        return results;
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function calculateCanvasDimension(newAvatars, oldAvatars) {
    let w, h;

    w = windowWidth;

    h = w * ASPECT_RATIO;
    updateCanvas(w, h, newAvatars, oldAvatars);
  }

  function updateCanvas(w, h, newAvatars, oldAvatars) {
    if (muralWidth !== w || muralHeight !== h || newAvatars?.length) {
      setMuralHeight(h);
      setMuralWidth(w);
      initMuralAvatars(w, h, newAvatars, oldAvatars);
    }
  }

  function initMuralAvatars(w, h, newAvatars, oldAvatars) {
    const boundary = calcBoundary(w, h);
    const _formattedAvatars = formatAvatars(w, h, boundary, newAvatars);
    const oldFormattedAvatars = fadeOutOldAvatars(oldAvatars);
    if (oldFormattedAvatars && oldFormattedAvatars.length) {
      setTimeout(() => {
        setFormattedAvatars(_formattedAvatars);
      }, 1000);
    } else {
      setFormattedAvatars(_formattedAvatars);
    }
  }

  function fadeInNewAvatars(avatars) {
    for (let i = 0; i < avatars.length; i++) {
      const curAvatar = avatars[i];

      curAvatar.ref?.current?.to({
        opacity: 1,
        easing: Konva.Easings.EaseIn,
        duration: 0.5,
      });
    }
  }

  function fadeOutOldAvatars(oldAvatars) {
    if (oldAvatars) {
      return [...oldAvatars].map((avatar, i) => {
        if (avatar.isUserOwnAvatar) {
          return avatar;
        }
        avatar?.ref?.current?.to({
          opacity: 0,
          easing: Konva.Easings.EaseOut,
          duration: 0.9,
        });
        return avatar;
      });
    }
  }

  function calcBoundary(w, h) {
    /**
     * IMPORTANT VALUES!
     * Proportions are needed to keep area consistent bc canvas dimensions are manually set.
     * */
    const _topLeftCorner = [w * 0.275, h * 0.59];
    const _topRightCorner = [w * 0.6, h * 0.22];
    const _botRightCorner = [w * 0.7, h * 0.31];
    const _botLeftCorner = [w * 0.37, h * 0.66];

    const boundary = {
      coordinates: [
        [_topLeftCorner, _topRightCorner, _botRightCorner, _botLeftCorner],
      ],
    };

    return boundary;
  }

  function loadBackgroundImage() {
    const image = new Image();
    image.src = mural;
    return image;
  }

  function formatAvatars(w, h, boundary, _avatars) {
    const avatarHeight = h * 0.08;
    const avatarWidth = avatarHeight * AVATAR_ASPECT_RATIO;

    const muralAvatars = _avatars ? _avatars : avatars;

    let nextRow = false;

    const avatarPositions = [];

    const _formattedAvatars = muralAvatars.map((avatar, i) => {
      const [x, y] = calculateAvatarPos(i, w, boundary, avatarPositions);

      if (i % NUM_IN_ROW === 0 && i !== 0) {
        nextRow = true;
      }

      const isUserOwnAvatar = userAddress === avatar?.eth_address;

      const metadata = {
        id: i.toString(),
        x,
        y,
        height: avatarHeight,
        width: avatarWidth,
        image: avatar?.back_image ? avatar.back_image : avatar?.front_image,
        nftImage: avatar?.nft_image,
        isDragging: false,
        isUserOwnAvatar,
        ref: createRef(null),
        tooltip: formatTooltip(avatar),
      };

      return metadata;
    });

    // Avatars "Zindex" is determined by drawn order.
    _formattedAvatars.sort((a, b) => a.y - b.y);
    setCoordinates(avatarPositions);
    // Save Zindex to return
    originalZIndexAvatars.current = _formattedAvatars;
    return _formattedAvatars;
  }

  function calculateAvatarPos(i, w, boundary, _coordinates) {
    const [topLeftX, topLeftY] = boundary.coordinates[0][1];
    const row = Math.floor(i / NUM_IN_ROW);
    const indexInRow = i % NUM_IN_ROW;
    const x = topLeftX + indexInRow * w * 0.017578 - row * w * 0.015234;
    const y = topLeftY + indexInRow * w * 0.0105468 + row * w * 0.00898;
    _coordinates.push([x, y]);
    return [x, y];
  }

  function handleZIndex(id) {
    const _formattedAvatars = formattedAvatars.slice();
    const _avatar = _formattedAvatars.find((i) => i.id === id);
    const index = _formattedAvatars.indexOf(_avatar);
    _formattedAvatars.splice(index, 1);
    _formattedAvatars.push(_avatar);
    setFormattedAvatars(_formattedAvatars);
  }

  function formatTooltip(avatar) {
    const { eth_address, level, token_id: tokenId } = avatar;

    const levels = {
      standard: "Member",
      gold: "Advocate",
      diamond: "Founder",
    };

    // importing from api file breaks
    let ens;
    const abbrevAddress = abbrevEthAddress(eth_address);
    const ethAddress = ens || abbrevAddress;
    return `${levels[level]}: #${numeral(tokenId).format(
      "0,0"
    )}\n${ethAddress}\n`;
  }

  function returnZIndex() {
    setFormattedAvatars(originalZIndexAvatars.current);
  }

  function showTooltip(e, tooltipText, nftImage) {
    document.body.style.cursor = "cursor";
    setTooltipImage(nftImage);
    const stage = e.target.getStage();
    const { x, y } = stage.getPointerPosition();
    const container = containerRef?.current;
    const tooltip = tooltipRef?.current;
    const nft = nftRef?.current;

    const xOffset = 25;
    const yOffset = 15;

    container.position({ x: x + xOffset, y: y - yOffset });
    tooltip.position({ x: x + nft.width() + xOffset, y: y - yOffset });
    nft.position({ x: x + xOffset, y: y - yOffset });

    tooltip.text(tooltipText);
    container.attrs = {
      ...container.attrs,
      width: nft.width() + tooltip.width(),
      height: nft.height(),
    };
    tooltip.show();
    container.show();
    nft.show();
  }

  function hideTooltip() {
    const container = containerRef?.current;
    const tooltip = tooltipRef?.current;
    const nft = nftRef?.current;
    document.body.style.cursor = "default";
    tooltip.hide();
    container.hide();
    nft.hide();
  }

  function randomColorAtInterval() {
    return Konva.Util.getRandomColor();
  }

  return (
    <Wrapper background={Colors.charcoal()}>
      <div className={css(styles.container)}>
        <Stage width={muralWidth} height={muralHeight} ref={canvasRef}>
          <Layer>
            <KonvaImage
              image={backgroundImage}
              width={muralWidth}
              height={muralHeight}
            />
            <Group>
              {
                isLoading && null
                // <Shape
                //   sceneFunc={(context, shape) => {
                //     const w = muralWidth;
                //     const h = muralHeight;
                //     const topLeftCorner = [w * 0.26, h * 0.62];
                //     const topRightCorner = [w * 0.619, h * 0.258];
                //     const botRightCorner = [w * 0.734, h * 0.3767];
                //     const botLeftCorner = [w * 0.38, h * 0.738];

                //     context.beginPath();
                //     context.moveTo(topLeftCorner[0], topLeftCorner[1]);
                //     context.lineTo(topRightCorner[0], topRightCorner[1]);
                //     context.lineTo(botRightCorner[0], botRightCorner[1]);
                //     context.lineTo(botLeftCorner[0], botLeftCorner[1]);
                //     context.lineTo(topLeftCorner[0], topLeftCorner[1]);
                //     context.closePath();
                //     // (!) Konva specific method, it is very important
                //     context.fillStrokeShape(shape);
                //   }}
                //   fill={randomColorAtInterval()}
                //   stroke={Colors.gray()}
                //   strokeWidth={2}
                // />
              }
              {formattedAvatars.map((avatar) => (
                <Avatar
                  key={`mural-avatar-${avatar.id}`}
                  avatar={avatar}
                  numOfAvatars={formatAvatars.length}
                  handleZIndex={handleZIndex}
                  returnZIndex={returnZIndex}
                  showTooltip={showTooltip}
                  hideTooltip={hideTooltip}
                  ref={avatar.ref}
                  onLastAvatarDrawn={() => setIsLoading(false)}
                />
              ))}
            </Group>
            <Rect
              ref={containerRef}
              stroke={Colors.lightCharcoal()}
              strokeWidth={1}
              fill={Colors.charcoal(0.8)}
              height={50}
              shadowColor={Colors.charcoal()}
              shadowBlur={10}
              shadowOffsetX={0}
              shadowOffsetY={0}
              shadowOpacity={0.7}
              cornerRadius={3}
            />
            <KonvaImage
              ref={nftRef}
              image={tooltipImage}
              visible={false}
              height={50}
              width={50}
              padding={15}
            />
            <KonvaText
              ref={tooltipRef}
              fontFamily={"GT Flexa Trial"}
              text={""}
              padding={8}
              fontSize={18}
              fill={Colors.lime()}
              textFill={Colors.gray()}
              visible={false}
              align={"left"}
            />
          </Layer>
        </Stage>
      </div>
    </Wrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    background: Colors.charcoal(0.8),
    filter: "blur(4px)",
    width: "100vw",
    height: "100%",
  },
  puzzle: {
    width: "100%",
    maxHeight: "calc(100vh - 300px)",
    objectFit: "cover",

    "@media only screen and (max-width: 767px)": {
      width: "unset",
    },
  },
  avatar: {
    height: 70,
    position: "absolute",
    bottom: 500,
    left: "calc(100vw / 2)",
    zIndex: 3,
    ":hover": {
      cursor: "pointer",
    },
  },
  avatar2: {
    height: 70,
    position: "absolute",
    bottom: 400,
    left: "calc(100vw / 2.5)",
    zIndex: 3,
    ":hover": {
      cursor: "pointer",
    },
  },
});

export default MuralCanvas;
